export const san_env = {
  REACT_APP_API_URL: "https://sea-dev.urbanform.us/api",
  REACT_APP_MAPBOX_ACCESS_TOKEN:
    "pk.eyJ1IjoicXZ0MjgyIiwiYSI6ImNrZmtxOWdveDB2ZXkyeG56NzR0aW9jbXUifQ.Plxub9XCzhhAbZ3MJbAXMw",
  REACT_APP_MAPBOX_STYLE_URL_BASIC:
    "mapbox://styles/qvt282/clquwyoxo010l01qwc4nmasi6",//multi-region-style
  REACT_APP_MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/cl6z1amot004r15qf654ijd1z",
  REACT_APP_MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/clgbse9yy000o01l31x9fk066",
  REACT_APP_MAPBOX_LAYER_NAME: "san_region",
  REACT_APP_STRIPE_KEY:
    "pk_test_51J3keRKwuRPRmetLYA1Zgpg1T50OlJj7L5U7p60MB7hvR8VaaXhoDk0llLi95tOaWUU77c4rNEx7fa22nyEStrxD00X4iN7RXg",
  REACT_APP_REGION: "san_diego",
  REACT_APP_BBOX: " -117.321625,32.529447,-116.783295,33.114549",
  REACT_APP_MAP_CENTER: "-117.151680,32.714222",
  REACT_APP_SUBREGION_LAYER:"",
  REACT_APP_SUB_REGION: null,
  REACT_APP_SUB_CITY_NAME: "",
  REACT_APP_CITY_NAME: "San Diego, California",
  REACT_APP_CITIES_LIST:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [] }',
  REACT_APP_PROXIMITY: "-117.321625,32.529447,-116.783295,33.114549",
  REACT_APP_MB_TILES_LIST:"taxlots-fill",
  REACT_APP_ZOOM_LEVEL:10.5
};
